import { keyframes, styled } from '@/styles/theme'
import * as Toast from '@radix-ui/react-toast'

const hide = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
})

const slideIn = keyframes({
  from: {
    transform: 'translateX(calc(100% + 25px))',
  },
  to: {
    transform: 'translateX(0)',
  },
})

export const ToastViewport = styled(Toast.Viewport, {
  position: 'fixed',
  top: 4,
  right: 4,

  margin: 0,
  zIndex: 0,
  outline: 'none',

  '@md': {
    top: 'unset',
    bottom: 88,
    right: 44.5,
  },
})

export const ToastIcon = styled('div', {
  display: 'flex',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'transparent',
  borderRadius: '$circular',
  padding: 0,

  variants: {
    variant: {
      information: {},
      error: {
        backgroundColor: '$error50',
        color: '$error90',
      },
      success: {
        backgroundColor: '$success50',
        color: '$success90',
      },
    },
  },
})

export const ToastClose = styled(Toast.Close, {
  width: 32,
  height: 32,

  cursor: 'pointer',
})

export const ToastRoot = styled(Toast.Root, {
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '$sm',

  '&[data-state="open"]': {
    animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${hide} 100ms ease-in`,
  },

  '> div': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 20,

    '> div svg:first-child': {
      width: 40,
      height: 40,
    },
  },

  variants: {
    variant: {
      information: {},
      error: {
        backgroundColor: '$error90',
        border: '1px solid $error70',
        boxShadow: '$errorGlow3',

        [`${ToastClose}`]: {
          color: '$error40',
        },
      },
      success: {
        backgroundColor: '$success90',
        border: '1px solid $success70',
        boxShadow: '$successGlow3',

        [`${ToastClose}`]: {
          color: '$success40',
        },
      },
    },

    size: {
      default: {
        maxWidth: 432,
        width: 432,
        height: 112,

        padding: '$3',
        gap: '$2',

        '> div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 20,

          [`${ToastIcon}`]: {
            width: 40,
            height: 40,
            padding: 8,
          },
        },

        [`${ToastClose}`]: {
          marginTop: -6,
        },
      },
      small: {
        maxWidth: 400,
        minHeight: 84,
        alignItems: 'center',

        padding: '20px $3',

        '> div': {
          gap: 12,

          [`${ToastIcon}`]: {
            width: 24,
            height: 24,

            backgroundColor: '$error50',
            color: '$error90',
          },
        },

        [`${ToastClose}`]: {
          width: 24,
          height: 24,
        },
      },
      mobile: {
        maxWidth: 312,
        minHeight: 84,

        padding: '20px 24px',
        gap: '20px',
        alignItems: 'center',
      },
    },
  },

  defaultVariants: {
    variant: 'information',
    size: 'mobile',
  },
})

export const ToastTitle = styled(Toast.Title, {
  color: '$error10',
  fontWeight: '$bold',
})

export const ToastDescription = styled(Toast.Description, {
  color: '$error10',
})
