import { globalCss } from '@stitches/react'

export const globalStyles = globalCss({
  '*, *:before, *:after': {
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
  },

  html: {
    fontSize: 16,
    lineHeight: 1,
  },

  body: {
    backgroundColor: '$neutral95',
    '-webkit-font-smoothing': 'antialiased',
  },

  '.grecaptcha-badge': {
    bottom: '90px !important',

    '@media screen and (max-width: 400px)': {
      display: 'hidden',
    },
  },

  '.sr-only': {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    borderWidth: 0,
  },
})
